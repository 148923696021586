header {
    @include absolute(rem-calc(70),auto,auto,50%,100);
    // @include absolute(0,auto,auto,50%,100);
    @include size(100%,auto);
    transform: translateX(-50%);
    .row {
        align-items: center;
        .cl {
          height: 100%;
          @include media-breakpoint-down(sm) {
            height: 52px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }
    }
    @include media-breakpoint-down(sm) {
        top: 10px;
    }
    .subpage-title {
      @include media-breakpoint-up(lg) {
        margin-top: 100px;
        font-weight: bolder;
        color: #fff;
        text-transform: uppercase;
        font-size: 4rem;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 15vw;        
      }
    }
}


.burger {
    // @include fixed(30px,15px,auto,auto,1000);
    transition: all 300ms ease-in-out;		
    // color: $colorPrimary;
    color: #fff;
    font-size: 10vw;	
    padding: 0 #{$globalPadding*0.5};	
    display: inline-block;		
    &.open {
        
    }    
    @include media-breakpoint-only(md) {
        font-size: 6vw;
        &:before {
            @include size(7vw,7vw);
        }
    }
    @include media-breakpoint-up(lg) {
        // display: none;
    }
    @include media-breakpoint-down(sm) {
        padding: 0;
    }

    .wrapper-menu {
          @include size(45px,30px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;
          transition: all 300ms ease-in-out;
         
        div {
              transition: all 300ms ease-in-out;
              transition: 0;
              &.half {
                  // width: 100%;
                  // transition: 0;
              }
        }
    }

    .wrapper-menu.open {
      transform: rotate(-45deg);  
    }

    .line-menu {
      background-color: #fff;
      border-radius: 5px;
      width: 100%;
      height: 3px;
    }

    .line-menu.half {
      width: 50%;
    }

    .line-menu.start {
      transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
      transform-origin: right;
    }

    .open .line-menu.start {
      transform: rotate(-90deg) translateX(8px);
    }

    .line-menu.end {
      align-self: flex-end;
      transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
      transform-origin: left;
    }

    .open .line-menu.end {
      transform: rotate(-90deg) translateX(-10px);
    }
}