body,html {
  position: relative;
  @include size(100%,100%);
  overflow-x: hidden;
  font-family: $baseFont;
}

body {
	background: #fff;
	z-index: 1;
	* {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
	.rotated {
		transform: rotate(-5deg);
	}
  	main {
		  overflow: hidden;
		.container {
			width: 95%;
			margin: 0 auto;
			max-width: 1400px;
		}
		

		span.color-primary {
			color: $colorPrimary;
		}

		/* typo */
		p {
			
		}
		.h2 {
			font-size: rem-calc(98);
			@include media-breakpoint-down(md) {
				font-size: 9vw;
			}
			@include media-breakpoint-down(sm) {
				font-size: 11vw;
			}
		}

		.funny-bg {
			&:before {
				content: '';
				@include absolute(-20%,auto,auto,0,0);
				@include size(100%,130%);
				transform: skewY(-5deg);
				background: $colorPrimary;
			}
		}

		.btn {
			border: 2px solid $colorPrimary;
			color: $colorPrimary;
			border-radius: 0;
			margin-top: 15px;
		}
		
	}
}

.moving-texts {

	.marquee {
		width: 300px;
		overflow: hidden;
		border: 1px solid #ccc;
		background: #ccc;
	  }

}

nav {
	@include fixed(0,0,0,0,10);
	@include size(100%,100%);
	background: $colorPrimary;
	padding: 30px;
	display: none;
	a {
		display: block;
		font-size: 8.5vmin;
		color: transparent;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: #fff;
		font-weight: bolder;
		transition: 300ms all;
		&:hover {
			text-decoration: none;
			color: #fff;
		}
	}
	&.show {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}


.preloader {
	@include fixed(0,0,0,0,1000000000);
	@include size(100%,100%);
	background: $colorPrimary;
	display: flex;
	justify-content: center;
	align-items: center;
	.inside {
		@include size(auto,auto);
		@include max(70vw,50vh);
		display: flex;
		justify-content: stretch;
		align-items: center;
		.image {
			display: block;
			padding: 15px;
		}
		.hello {
			display: block;
			padding: 15px;
			text-align: left;
			.welcome {
				font-size: rem-calc(60);
				color: transparent;
                -webkit-text-stroke-width: 2px;
				-webkit-text-stroke-color: #fff;
				margin-bottom: rem-calc(10);
				font-weight: bolder;
				line-height: 100%;
			}
			.guest {
				font-size: rem-calc(60);
				color: #fff;
				margin-bottom: 0;
				line-height: 100%;
				font-weight: bolder;
				text-transform: uppercase;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.inside {
			flex-direction: column;
			@include max(90vw,none);
			.hello {
				.welcome,
				.guest {
					font-size: 12vw;
				}
			}
		}
	}
}
