footer {
    position: relative;
    @include size(120vw,auto);
    margin: rem-calc(100) auto rem-calc(-100) -10vw;
    background-color: $colorPrimary;
    padding-top: rem-calc(85);
    * {
        color: #fff;
    }
    a {
        &:hover {
            color: inherit;
        }
    }

    @include media-breakpoint-up(lg) {
        height: rem-calc(285);        
    }
}

.cookiesNotification {
    @include fixed(auto,auto,0,0,100);
    @include size(100%,auto);
    padding: rem-calc(15);
    background: #f8f8f8;
    color: #000;
    p {
        display: inline-block;
        float: left;
        margin: 0;
        padding: 10px;
    }
    .closeNotification {
        display: inline-block;
        border: 1px solid #000;
        padding: 10px;
        float: right;
        cursor: pointer;
        background: #000;
        color: #fff;        
    }
}