section {
    position: relative;    
    .section-header {
        margin-bottom: rem-calc(100);
        text-transform: uppercase;
        font-size: rem-calc(17);
        letter-spacing: 2px;
        color: #000;
        font-weight: bolder;
        position: relative;
        &:before {
            content: url('../img/dash-before.png');
            @include absolute(0,auto,auto,50%,10);
            transform: translate(-50%,rem-calc(-25));
        }
        &:after {
            content: url('../img/dash-before.png');
            @include absolute(auto,auto,0,50%,10);
            transform: translate(-50%,rem-calc(25)) rotate(180deg);
        }
    }
    &.hero {
        // min-height: 600px;
        // padding: rem-calc(250) 0 rem-calc(150);      
        padding: 0;      
        @include media-breakpoint-down(md) {
            // padding: 35vw 0;
            padding: 0 0;
        }  
        @include media-breakpoint-down(sm) {
            // padding: 35vw 0 25vw;
            // padding: 0 0 25vw;
            padding: 0 0 0;
        }  
        .video-container {
            display: block;
            position: relative;
            &:after {
                content: '';
                @include absolute(0,0,0,0,1);
                @include size(100%,100%);                
                display: block;
            }
            video {
                @include size(100%,auto);
                @include max(100%,auto);
            }
        }
        .container {
            @include absolute(50%,auto,auto,50%,1);
            transform: translate(-50%,-50%);
        }
        .hero-txt {
            
            color: #fff;
            .huge {
                font-size: rem-calc(240);
                line-height: 80%;
                // text-shadow: 0 0 3px #fff;
                // color: $colorPrimary;
                font-weight: bolder;

                color: transparent;
                -webkit-text-stroke-width: 3px;
                -webkit-text-stroke-color: #fff;
                margin: 0;
                @include media-breakpoint-down(md) {
                    font-size: 10vw;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 17vw;
                }
            }
            .h1 {
                span {
                    color: #000;
                }
                @include media-breakpoint-down(md) {
                    font-size: 3vw;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 6vw;
                }
            }
        }
    }
    &.about {
        // padding: rem-calc(250) 0;
        padding: rem-calc(150) 0;
        @include media-breakpoint-down(md) {
            // padding: 28vw 0 7vw;
            padding: 4vw 0 7vw;
        }
        @include media-breakpoint-down(sm) {
            // padding: 40vw 0 0vw;
            padding: 20vw 0 0vw;
        }
        .skills {
            padding: rem-calc(65) 0;
            figure {
                @include size(85px,85px);
                display: inline-flex;
                justify-content: flex-end;
                align-items: flex-end;
                border: 3px solid $colorPrimary;
                margin: 0 rem-calc(30);
                padding: 4px;
                transition: all 250ms ease-in-out;
                p {
                    margin: 0 2px -5px 0;
                    color: $colorPrimary;
                    font-weight: bolder;   
                    font-size: 23px;              
                    transition: all 250ms ease-in-out;
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        background: $colorPrimary;
                        p {
                            color: #fff;
                        }
                    }
                }
            }
            @include media-breakpoint-down(md) {
                padding: 4vw 0;
                figure {
                    margin: 2vw;
                }
            }
            @include media-breakpoint-down(sm) {
                padding: 4vw 0;
                figure {
                    margin: 2vw;
                }
            }
        }
    }
    &.moving-texts {
        padding: rem-calc(190) 0;
        margin: rem-calc(160) auto;
        @include media-breakpoint-down(md) {
            padding: 0;
        }         
        @include media-breakpoint-down(sm) {
            padding: 0;
        }         
        .loop {
            display: block;
            overflow: hidden;
            .item {
                color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #fff;
                font-size: rem-calc(125);
                display: inline-block;
                margin: 0 25px;
                font-weight: bolder;
                transition: all 200ms;
                color: transparent;
                &:not(.no-hover) {
                    &:hover {
                        color: #fff;
                        text-decoration: none;
                    }
                }
                &.highlight {
                    color: #fff;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 20vw;
                }                                
            }
        }
    }    
    &.portfolio {
        overflow-x: hidden;
        padding: rem-calc(190) 0;
        @include media-breakpoint-down(sm) {
            padding: 0 0;
        }
        .portfolio-item {
            padding: rem-calc(50) 0;
            .main-details {
                position: relative;
                .project-name {
                    display: block;
                    font-size: rem-calc(80);
                    text-align: right;
                    color: $colorPrimary;
                    text-decoration: none !important;
                    margin-bottom: 0;
                    line-height: 90%;
                    font-weight: 900;
                    transition: all 250ms ease-in-out;
                    @include media-breakpoint-down(sm) {
                        font-size: 9vw;
                    }
                    &:hover {
                        color: transparent;
                        -webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: $colorPrimary;
                    }
                }
                .scope-of-work {
                    text-align: right;                
                    color: $colorPrimary;
                    margin-bottom: 0;
                    font-size: rem-calc(20);
                }
                &:after {
                    content: '';
                    @include absolute(auto,0,-20px,auto,10);
                    @include size(150vw,10px);
                    background-color: $colorPrimary;
                    transform: rotate(-5deg);
                    transform-origin: 90%;
                }
            }
        }
        .description {
            padding: 0 rem-calc(40);
        }
    }
    &.contact {
        .h2 {
            color: $colorPrimary;
            span {
                background: $colorPrimary;
                color: #fff;
                padding: 3px 6px;
                cursor: pointer;
                transition: all 250ms;
                &:hover {
                    background: #000;
                    color: $colorPrimary;
                }
            }
            a {
                text-decoration: none !important;
            }
        }
    }
    &.subpage {
        &:before {
            content: '';
            @include absolute(-20%,auto,auto,0,0);
            @include size(100%,auto);
            transform: skewY(-5deg);
            background: $colorPrimary;
        }
        .headers {
            margin-bottom: 6rem;
            h2 {
                text-align: left;
                font-size: 4rem;
                color: $colorPrimary;
                text-transform: uppercase;
                font-weight: bolder;
                margin-bottom: 0;
                @include media-breakpoint-down(sm) {
                    font-size: 8.5vw;
                    line-height: 100%;
                    margin-bottom: 2.5vw;
                }
            }
            h3 {
                text-align: left;
                color: #010101;
                font-size: 2.4rem;
                margin-top: -0.5rem;
                @include media-breakpoint-down(sm) {
                    font-size: 6.5vw;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            padding-top: 400px;
            &:before {
                top: -80px;
                height: 400px;                
            }            
            .container {
                width: 60%;
                min-width: 1000px;
            }
        }
        @include media-breakpoint-only(md) {
            padding-top: 15vh;
            &:before {
                top: -3%;
                height: 15vh;
            }
        }
        @include media-breakpoint-down(sm) {
            padding-top: 35vh;
            &:before {
                top: -3%;
                height: 30vh;
            }
        }

        .container {
            img {
                @include max(100%,100%);
                height: auto;
            }
        }
    }
}