body.noScroll {
	overflow:  hidden;
}

.off {
    pointer-events: none;    
}

@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}