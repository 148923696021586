@charset 'UTF-8';

// Vendors
@import
  'bootstrap/bootstrap',
  'vendors/normalize',
  'vendors/bootstrap',
  'vendors/misc',
  'vendors/slick',
  'vendors/original';

// Common
@import
  'common/variables',
  'common/mixins',
  'common/helpers',
  'common/common',
  'common/header',
  'common/footer';

// Pages
@import  
  'pages/home';